<template>
<div class="relative md:pt-32 pb-32 pt-12  " style="z-index:1;">
     <h2 class="text-xl mt-4">จัดการข้อมูลการประเมิน</h2>
</div>
</template>

<script>
export default {
    data:()=>{
        return ({

        })
    },
    async created(){

    },
    methods:{
        
    }
}
</script>

<style>

</style>
